
































































































import Vue from 'vue';

export default Vue.extend({
  name: 'login',

  data: () => ({
    rememberMe: false,
    email: '',
    password: '',
    valid: false,
    showPassword: false,
    loading: false,
    message: '',
    messageType: 'success',
  }),
  computed: {
    emailRules() {
      return [(v) => /.+@.+\..+/.test(v) || this.$t('dashboard.invalid-email')];
    },
    passwordRules() {
      return [
        (str) => !!str || this.$t('dashboard.invalid-password'),
        (str) => str.length >= 7 || this.$t('dashboard.invalid-password-lenght'),
      ];
    },
  },
  mounted() {
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.send();
      }
    });
    this.message = this.$route.params.message;
  },

  methods: {
    async send() {
      if (this.valid) {
        this.loading = true;
        try {
          await this.$store.dispatch('auth/login', {
            username: this.email,
            password: this.password,
          });
          this.loading = false;
          this.$router.push('/');
        } catch (error) {
          console.log('error in Login - send');
          console.log(error);
          this.messageType = 'error';
          this.message = this.$t('dashboard.login-invalid-data').toString();
          this.loading = false;
        }
      }
    },
    createAccount() {
      this.$router.push('create-account');
    },

    forgot() {
      this.$router.push('reset-password');
    },
  },
});
